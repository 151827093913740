<template>
	<div class="wrapper_main">
		<main class="content">
			<div class="content__header">
				<div class="content__header-left">
					<h1 class="heading-page-h1">{{ $translate('company') }}</h1>
				</div>
				<div class="content__header-right">
					<router-link to="/settings" class="btn btn-w-shadow btn-standart-2">
						<span>{{ $translate('edit-info') }}</span>
					</router-link>
				</div>
			</div>
			<div class="content__body">
				<div class="company-page">
					<div class="company-page__img" v-if="media.length">
						<img :src="media[0].url">
					</div>
					<div class="company-page__info">
						<h3>{{ companyData.company_name }}</h3>
						<span>{{ $translate('description-of-the-company') }}</span>
						<p>
							{{ companyData.history_of_company }}
						</p>
					</div>
					<div class="company-page__info">
						<ul>
							<li>
								<span>
									{{ $translate('year-of-foundation') }}
								</span>
								<p>
									{{ companyData.year_of_foundation }}
								</p>
							</li>
							<li>
								<span>
									{{ $translate('website') }}
								</span>
								<p>
									{{ companyData.website }}
								</p>
							</li>
							<li>
								<span>
									{{ $translate('location') }}
								</span>
								<p>
									{{ companyData.location }}
								</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<h3>{{ $translate('product-&-farm-info') }}</h3>
						<ul>
							<li>
								<span>
									{{ $translate('production-/-year') }}
								</span>
								<p>
									{{ companyData.production_per_year }}
								</p>
							</li>
							<li>
								<span>
									{{ $translate('production-area') }}
								</span>
								<p>
									{{ companyData.production_area }}
								</p>
							</li>
							<li>
								<span>
									{{ $translate('soils') }}
								</span>
								<p>
									{{ companyData.soils }}
								</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<ul>
							<li>
								<span>
									{{ $translate('cellar-work') }}
								</span>
								<p>
									{{ companyData.cellar_work }}
								</p>
							</li>
							<li>
								<span>
									{{ $translate('productivity') }}
								</span>
								<p>
									{{ companyData.productivity }}
								</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info inner">
						<ul>
							<li>
								<span>
									{{ $translate('philosophy') }}
								</span>
								<p>
									{{ companyData.philosophy }}
								</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<ul>
							<li>
								<span>
									{{ $translate('vat-number') }}
								</span>
								<p>
									{{ companyData.vat }}
								</p>
							</li>
							<li>
								<span>
									{{ $translate('fiscal-code') }}
								</span>
								<p>
									{{ companyData.fiscal }}
								</p>
							</li>
						</ul>
					</div>
					<div class="company-page__info">
						<h3>{{ $translate('location') }}</h3>
						<div class="map">
							<img src="@/assets/img/map-home-location.jpg">
						</div>
					</div>

					<!--   Certifications    -->
					<div class="item-body mt-15">
						<span class="weight-500">{{ $translate('certifications') }}</span>
						<ul class="editable-list">
							<li class="editable-list__item" v-for="m in certificates" :key="m.id">
								<div class="data-row">
									<ul class="data-row__list attachment-list">
										<li class="data-row__item attachment-list-title">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path d="M8 13H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path d="M8 17H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path fill-rule="evenodd" clip-rule="evenodd" d="M6 3H15.172C15.702 3 16.211 3.211 16.586 3.586L19.414 6.414C19.789 6.789 20 7.298 20 7.828V19C20 20.105 19.105 21 18 21H6C4.895 21 4 20.105 4 19V5C4 3.895 4.895 3 6 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path d="M20 8H16C15.448 8 15 7.552 15 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path d="M8 9H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
											<span style="margin-left: 10px">{{ m.title }}</span>
										</li>
										<li class="data-row__item">
											<a target="_blank" :href="m.url" class="btn btn-primary btn-sm">{{ $translate('download') }}</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>

					<!--   Documentation   -->
					<div class="item-body mt-15">
						<span class="weight-500">{{ $translate('documentation') }}</span>
						<ul class="editable-list">
							<li class="editable-list__item" v-for="m in documents" :key="m.id">
								<div class="data-row">
									<ul class="data-row__list attachment-list">
										<li class="data-row__item attachment-list-title">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
												<path d="M8 13H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path d="M8 17H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path fill-rule="evenodd" clip-rule="evenodd" d="M6 3H15.172C15.702 3 16.211 3.211 16.586 3.586L19.414 6.414C19.789 6.789 20 7.298 20 7.828V19C20 20.105 19.105 21 18 21H6C4.895 21 4 20.105 4 19V5C4 3.895 4.895 3 6 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path d="M20 8H16C15.448 8 15 7.552 15 7V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												<path d="M8 9H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
											</svg>
											<span style="margin-left: 10px">{{ m.title }}</span>
										</li>
										<li class="data-row__item">
											<a target="_blank" :href="m.url" class="btn btn-primary btn-sm">{{ $translate('download') }}</a>
										</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>

					<!-- Media -->
					<div class="item-body mt-15">
						<span class="weight-500">{{ $translate('media') }}</span>
						<div class="gallery mt-15">
							<div class="gallery__item" v-for="(m, index) in media" :key="m.id" @click="openGallery(index)">
								<img :src="m.url">
							</div>
						</div>
					</div>
					
					<hr class="line-hr my-25">
					<div class="company-page__related-fields" v-show="false">
						<div class="headline headline--sm">
							<h2 class="headline__title">{{ $translate('related-fields') }}</h2>
						</div>
						<ul class="editable-list">
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Field Name 1</li>
										<li class="data-row__item">123</li>
										<li class="data-row__item">Crop Name</li>
										<li class="data-row__item">Variety Name</li>
									</ul>
								</div>
							</li>
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Field Name 2</li>
										<li class="data-row__item">123</li>
										<li class="data-row__item">Crop Name 2</li>
										<li class="data-row__item">Variety Name 2</li>
									</ul>
								</div>
							</li>
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">Field Name 3</li>
										<li class="data-row__item">123</li>
										<li class="data-row__item">Crop Name 3</li>
										<li class="data-row__item">Variety Name 3</li>
									</ul>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</main>
		<Gallery v-if="galleryShow" :media="media" :currentImage="currentImage" @close="closeGallery"/>
	</div>	
</template>

<script>
import { Storage } from 'aws-amplify';
import Base from '@/components/base'
export default {
	components: {
		...Base
	},
	data() {
		return {
			media: [],
			certificates: [],
			documents: [],
			galleryShow: false,
			currentImage: 0
		}
	},
	computed: {
		companyData() {
			return this.$store.state.companyData || {}
		},
	},
	created() {
		this.getAttachments();
	},
	methods: {
		async getAttachments() {
			let requests = this.companyData.media.items.map(async item => { 
				return {
					id: item.id,
					key: item.key,
					url: await Storage.get(item.key) 
				}	
			});

			let documentRequests = this.companyData.documentation.items.map(async item => {
				return {
					id: item.id,
					key: item.key,
					url: await Storage.get(item.key),
					title: item.title 
				}
			})

			let certificatesRequests = this.companyData.certifications.items.map(async item => {
				return {
					id: item.id,
					key: item.key,
					url: await Storage.get(item.key),
					title: item.title 
				}
			})

			Promise.all(requests).then(responses => this.media = responses);
			Promise.all(documentRequests).then(responses => this.documents = responses);
			Promise.all(certificatesRequests).then(responses => this.certificates = responses);
		},

		closeGallery() {
			this.galleryShow = false;
		},
		openGallery(i) {
			this.currentImage = i;
			this.galleryShow = true;
		}
	}
}
</script>

<style>

</style>